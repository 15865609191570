import React from 'react';
import { Link, SectionText, SectionTitle } from '../../Typography';

export default function ProfessionalProfile() {
  const [readMore, setReadMore] = React.useState(false);

  const handleReadMore = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setReadMore(!readMore);
  };

  return (
    <>
      <SectionTitle>Professional profile</SectionTitle>

      <SectionText>
        Software Engineer with over{' '}
        <strong>6 years of experience with JavaScript</strong>, currently
        working at <strong>Opensea</strong> with a focus on{' '}
        <strong>React and Typescript</strong>, and looking for an opportunity to
        be part of a forward-thinking product team where I can contribute to
        building a great product.
      </SectionText>

      {!readMore && (
        <Link href="#" onClick={handleReadMore}>
          Read more
        </Link>
      )}

      {readMore && (
        <SectionText>
          I really enjoy working on complex problems with teams of talented
          people. I hate being the smartest guy in the room. I know a thing or
          two about design. I’m a very driven and committed person and like
          challenges that push me to learn new things.
        </SectionText>
      )}
    </>
  );
}
